.head {
	width: 100%;
	height: 40px;
	padding-top: 5px;
	color: #f27c23;
	font-weight: bold;
	font-size: 13px;
}

.title-item-list {
	width: 100%;
}

.title-item-list .col {
	padding: 5px;
}

.item-row {
	display: flex;
	flex-direction: row;

	.content {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
	}
	.actions {
		display: flex;
		flex-direction: row;
		width: 120px;
		flex-grow: 0;
	}
}
.list-row {
	width: 100%;
	padding: 6px;
	&:nth-child(even) {
		background-color: #f2f2f2;
	}
}
.sub-item-row {
	margin-top: 25px;
	display: flex;
	flex-direction: row;
	padding: 0 0 15px 0;

	h4 {
		padding: 6px;
		margin: 0 0 10px 0;
	}

	.span {
		width: 20px;
		flex-grow: 0;
		margin-right: 5px;
	}

	.sub-list {
		flex-grow: 1;
		box-shadow: -3px -2px 5px 0 rgba(100, 100, 100, 0.1);
		border: 1px dotted #ccc;
		background-color: #ffffff;
		margin-left: 20px;
	}
}

.list-body {
	display: flex;
	flex-direction: row;
}

.icon-group {
	width: 100%;
	justify-content: flex-end;
}
