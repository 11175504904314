/* --- BUTTONS --- */
.btn{
	text-align: center;
	display: inline-block;
	color: #fff;
	background: #f27c23;
	font: 18px/22px 'Lato Regular', 'Arial', Helvetica, sans-serif;
	padding: 5px 10px;
	cursor: pointer;
	transition: all 0.2s ease;
	border: 1px solid transparent;
}
.btn:hover{
	background: #dd5f00;
	text-decoration: none;
}
.btn-gray{
	background: #b3b3b3;
}
.btn-gray:hover{
	background: #909090;
}
.btn+.btn{
	margin: 0 0 0 6px;
}

.btn-white{
	background: #fff;
	border-color: #d7d7d7;
	color: #999;
}
.btn-white:hover{
	background: #fafafa;
}
.btn-block{
	display: block;
	width: 100%;
}



.btn-setting {
	background: #fff;
	color: #f27c23;
	border: 1px solid #d7d7d7;
	padding: 7px !important;
	border-left: 0;
}

.btn-setting:hover {
	background: #e2e2e2;
	background: #f2f2f2;
}
