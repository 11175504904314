.login-box{
  margin: 100px auto 0;
  max-width: 384px;
}
.pw-reset-box{
  margin: 100px auto 0;
  max-width: 430px;
}
.login-box h1{
  margin: 0 0 10px;
  font-size: 30px;
  line-height: 34px;
}
.login-form{
  background: #fff;
  border: 1px solid #d7d7d7;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  padding: 30px 30px 40px;
  font-size: 16px;
  line-height: 20px;
}

.login-form .checkbox{
  font-size: 14px;
  display: inline;
  float:left;
}
.login-form .form-control{
  height: 38px;
  padding: 7px 10px;
}
.login-form .btn{
  height: 38px;
  padding: 7px 12px;
  float: right;
}

.remember-submit {
  margin-top: 15px;
  display: block;
}

.login-form .form-group{
  margin: 0 0 10px;
}
