.table{
  width: 100%;
  margin: 0 0 20px;
  color: #888;
  background: #fff;

  th, td{
    vertical-align: top;
    padding: 15px;
    transition: background 0.2s ease;
  }

  th{
    color: #f27c23;
    font-weight: normal;
    text-align: left;
    font-size: 18px;
    line-height: 22px;
    border: solid #d7d7d7;
    border-width: 1px 0;
    vertical-align: middle;
  }

  th:first-child{
    border-left: 1px solid #d7d7d7;
  }

  th:last-child{
    border-right: 1px solid #d7d7d7;
  }

  td:last-child{
    border-right: 1px solid #fff;
  }

  td:first-child{
    border-left: 1px solid #fff;
  }

  tr{
    position: relative;
  }

  tr:nth-child(even) td:last-child{
    border-right: 1px solid #f2f2f2;
  }

  tr:nth-child(even) td{
    background: #f2f2f2;
  }
}

.table-xs{
  td{
    padding: 2px;
    background: #fff !important;
  }

  th{
    padding: 5px 2px;
    border: none !important;
  }
}









.users-table{
  /*font-size: 18px;
  line-height: 22px;
  color: #333;*/

  td{
    vertical-align: middle;
  }

  td,
  th{
    padding: 15px 0 15px 10px;
  }
  td:last-child,
  th:last-child{
    padding-right: 10px;
  }
}


/*
.sub-table{
  font-size: 16px;
  line-height: 22px;
  color: #333;

  th, td{
    padding: 8px 10px 8px 0;
    vertical-align: middle;
  }

  th:first-child,
  td:first-child{
    padding-left: 10px;
  }

  .col-actions{
    width: 83px;
  }

  .edit-tr .in-holder{
    margin: -10px -5px -10px 0;
  }
  .edit-tr .col-actions{
    text-align: right;
  }

  .edit-tr .col-actions button,
  .edit-tr .col-actions a{
    opacity: 1;
  }
  .edit-tr .icon-group > span{
    border: none;
    box-shadow: none;
    padding-right:6px;
  }

  &:not(.table-header) .edit-tr{
    display: none;
  }
  .open-edit-tr tr{
    display: none;
  }
  .open-edit-tr .edit-tr{
    display: table-row;
  }
}
*/

.users-table .col-name{
  width: 280px;
}
.table.sub-table .edit-tr td{
  background: #e5e5e5;
}

.table-holder .table{
  margin: 0;
}
.table-scroll{
  overflow-x: hidden;
  overflow-y: auto;
  width:100%;
  border: 1px solid lightgrey;
}

/*
.signature-col{
  width: 100px;
}
.sig-col{
  width: 140px;
}
.no-col{
  width: 65px;
}
.addition-col{
  width: 84px;
}
.language-col{
  width: 99px;
}
.labels-col{
  width: 65px;
}
.copies-col{
  width: 75px;
}
*/
/*
.date-col:first-child{
  width: 120px;
}
.col-actions:nth-last-child(1){
}
*/
/*
.sub-table .edit-tr .in-holder.sub{
  margin-right: -38px;
}
*/
/*
.edit-tr .col-actions .fa-plus-circle{
  font-size: 26px;
  color:#8c8c8c;
}
.edit-tr .col-actions .fa-plus-circle:hover{
  color:#f27c23
}
*/

/*
.col-year{
  width: 160px;
}
.col-location{
  width: 186px;
}
.col-actions{
  width: 175px;
}
*/
.col-actions.small{
  width: 93px;
}
.table-small.search-table .col-actions{
  width: 86px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.table-small.edit-table .col-actions{
  width: 66px;
}
.table-small.edit-table .col-actions.three
{
  width: 93px;
}
/*
.col-chk{
  width: 46px;
}
.sub-table .col-chk{
  width: 31px;
}
*/
.table.tr-link tr:hover>td{
  background: #fef2e9;
}
.table.tr-link tr:hover>td:first-child{
  box-shadow: 3px 0 0 #f27c23 inset;
  border-left-color: #f27c23;
}
.tr-link td{
  /*cursor: pointer;*/
}
td[ng-click]{
  cursor: pointer;
}
.edit-row{
  display: none;
}
.open-edit-row tr{
  display: none;
}
.open-edit-row .edit-row{
  display: table-row;
}
.table.tr-link input[type='checkbox'],
.table.tr-link td a{
  z-index: 2;
  position: relative;
}

/*
.expand-row{
  display: none;
}
.expand-row.open{
  display: table-row;
}
.expand-row .checkbox:first-child{
  margin: 0;
}
*/
/*
.icon-group .btn-expand-table{
  width: 41px;
}
.btn-expand-table a{
  position: relative;
}
.btn-expand-table a.active{
  opacity: 1;
}
.btn-expand-table a:after{
  content: "\f0d7";
  font-family: 'FontAwesome';
  font-size: 12px;
  color: #221f20;
  position: absolute;
  left: 100%;
  margin: 0 0 0 5px;
  top: -2px;
}
.btn-expand-table a.active:after{
  content: "\f0d8";
}
.users-table .col-actions{
  width: 110px;
}
*/
/*.table.users-table td{*/
/*background: #f00;*/
/*}*/
/*.table.users-table td:last-child{*/
/*border-right: 1px solid #fff;*/
/*}*/
/*.table.users-table td:first-child{*/
/*border-left: 1px solid #fff;*/
/*}*/


/*.table.users-table tr:nth-child(4n+4) td:first-child{*/
/*border-left: 1px solid #fff;*/
/*}*/
/*.table.users-table tr:nth-child(4n+4) td:last-child{*/
/*border-right: 1px solid #fff;*/
/*}*/
/*.table.users-table tr:nth-child(4n+4) td{*/
/*background: #fff;*/
/*}*/
/*.table-small.search-table .icon-group > span,*/

.volume-actions .icon-group > span,
.table-small.edit-table .icon-group > span{
  height: 23px;
  line-height: 1;
  border: none;
  box-shadow: none;
  width: auto;
  margin: 0 0 10px;
}
/*.table-small.search-table .icon-group > span+span,*/
.volume-actions .icon-group > span+span,
.table-small.edit-table .icon-group > span+span{
  padding-left: 5px;
}
.volume-actions .icon-group > span:last-child,
.table-small.search-table .icon-group:last-child > span,
.table-small.edit-table .icon-group:last-child > span{
  margin-bottom: 0;
}

/*
.volume-actions .icon-group a,
.search-table .col-actions .icon-group a,
.edit-table .col-actions .icon-group a{
  opacity: 0.5;
}
.volume-actions .icon-group a:hover,
.volume-actions .icon-group a.active,
.search-table .col-actions .icon-group a.active,
.search-table .col-actions .icon-group a:hover,
.edit-table .col-actions .icon-group a.active,
.edit-table .col-actions .icon-group a:hover{
  opacity: 1;
}
*/


.search-table,
.edit-table{
  color: #333;
}
.search-table tr:nth-child(2n) td{
  background: #fff;
}
.search-table tr:nth-child(odd) td,
.sub-table tbody:nth-child(2n) tr:first-child td{
  background: #f2f2f2;
}
.search-table tr:nth-child(odd) td:first-child,
.sub-table tbody:nth-child(2n) tr:first-child td:first-child,
.edit-table tbody:nth-child(2n) tr:first-child td:first-child{
  border-left: 1px solid #f2f2f2;
}
.search-table tr:nth-child(odd) td:last-child,
.sub-table tbody:nth-child(2n) tr:first-child td:last-child,
.edit-table tbody:nth-child(2n) tr:first-child td:last-child{
  border-right: 1px solid #f2f2f2;
}
.edit-row td{
  padding: 0 !important;
}
.edit-block{
  padding: 29px 28px 14px;
  background: #fff;
  border: 1px solid #d7d7d7;
}

.volume-actions{
  float: right;
  padding: 0 0 0 10px;
}
.volume-list .gray-box{
  margin: 0;
}
.volume-content{
  overflow: hidden;
  color: #333;
  font-size: 14px;
  line-height: 18px;
}
.volume-item{
  width:100%;
  overflow: hidden;
  padding: 10px 7px;
}
.volume-list>div:nth-child(2n) .volume-item{
  background: #f2f2f2;
}

.open-close .table{
  color: #333;
}
.opener-title.mb0+.open-close-content .table{
  margin: -1px 0 0;
}
.table.table-small th,
.open-close .table th{
  font-weight: bold;
  border-color: #ccc;
  font-size: 13px;
  line-height: 17px;
}
.table.table-small td.form{
  padding: 0;
}
.table.table-small th,
.table.table-small td{
  padding: 10px 7px;
}

.fixed-table{
  table-layout: fixed;
}

.book-cover{
  width: 80px;
}

table.results td {
  line-height: 18px;
  font-size: 16px;
}

table.results td div {
  line-height: 23px;
  font-size: 16px;
}

table.results td div.mb5 {
  line-height: 18px;
  font-size: 16px;
}
