table{
  .col-title{
    min-width:220px;
  }
  .icon-group{
    span.icon-attachment{
      cursor: pointer;
    }
  }
  tr{
    &.disabled-row{
      color:#b1b1b1;
    }
    &.volumes-copy-list{
      h3{
        margin:0 0 5px 0;
      }
      .single-magazine{
        border-bottom:1px solid #ccc !important;
        padding:2.5px 0 5px 0;
        &:last-of-type{
          border:none !important;
        }
        div.magazine-info{
          float:left;
        }
        div.col-actions{
          float:right;
          padding-top:10px;
        }
      }
    }
    th{
      border:none !important;
      border-bottom:1px solid #ccc !important;
    }
    .column-price{
      width:100px;
    }
  }
  &.volumes-table{
    .col-actions{
      width:120px !important;
      text-align:right;
    }
  }
}