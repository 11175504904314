.settings-holder:after,
.date-group:after,
.gray-wrap:after,
.main-half:after,
#nav:after,
.modal-top:after,
.row:after,
.half-group:after,
.modal-main:after,
.pagination:after,
.tabs-holder:after,
.expert-holder:after,
#header:after,
.clearfix:after{
	content:'';
	display:table;
	clear:both;
}
html,body{
	height: 100%;
	font-size: 15px;
}
body{
	min-width: 1000px;
	font: 100% 'Lato Regular', 'PT Sans', Arial, Helvetica, sans-serif;
	color: #333;
	background: #fff;
	margin: 0;
	-ms-word-wrap: break-word;
	word-wrap: break-word;
	-ms-text-size-adjust: none;
	-webkit-text-size-adjust: none;
}
*{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

*:before,
*:after{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
strong, b, h1, h2, h3 {
	font: 100% 'Lato Bold', 'PT Sans', Arial, Helvetica, sans-serif;
	font-weight: bold;
}
h4, h5, h6 {
	font: 100% 'Lato Regular', 'PT Sans', Arial, Helvetica, sans-serif;
	font-weight: normal;
}

input[type=text], input[type=submit], input[type=button], input[type=password], input[type=file], textarea{-webkit-appearance:none;}
textarea{resize:vertical;}
.text-right{text-align:right;}
.text-left{text-align:left;}
.text-center{text-align:center;}
.pull-left{float: left !important;}
.pull-right{float: right !important;}
.display-block{display: block !important;}
button.display-block{width: 100%;}
.m0{
	margin: 0 !important;
}
.mb0{
	margin-bottom: 0 !important;
}
.mb5{
	margin-bottom: 5px !important;
}
.mb10{
	margin-bottom: 10px !important;
}
.mb15{
	margin-bottom: 15px !important;
}
.mb20{
	margin-bottom: 20px !important;
}
.mb25{
	margin-bottom: 25px !important;
}
.text-muted{
	color: #999;
}
.text-gray{
	color: #666;
}
.text-small{
	font-size: 12px;
	line-height: 14px;
}

h2 {
	font-size: 26px;
	margin: 0;
}

h2.headline-page {
	margin: 10px 0 25px 0;
}

h2.headline-dialog {
	margin: 0 0 0 0;
}

h3 {
	font-size: 21px;
	margin: 0;
}

h3.headline-section {
	margin: 25px 0 10px 0;
}

h4 {
	font-size: 15px;
	margin: 0;
}

h4.headline-tab {
	color: #333;
	cursor: pointer;
	display: inline;
}

h4.branded {
	color: #f27c23;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
blockquote, q{quotes:none;}
blockquote:before, blockquote:after,
q:before, q:after{
	content:'';
	content:none;
}
p{
	margin: 0 0 20px;
}
ul{
	list-style: none;
	padding: 0;
	margin: 0;
}
table{
	border-collapse:collapse;
	border-spacing:0;
}
img{
	vertical-align:top;
	border-style:none;
	max-width: 100%;
	height: auto;
}
a{
	text-decoration: none;
	color:#f27c23;
	cursor: pointer;
	outline: none;
}
a:hover{
	text-decoration: underline;
}
.visibility-hidden{visibility: hidden;}
.alt-row{
	overflow: hidden;
	padding: 0 10px 0 0;
}
.layout-fix-holder{
	display: table;
	table-layout: fixed;
	width: 100%;
	word-wrap: break-word;
}
.layout-fix{
	display:table;
	padding: 0 8px 0 0;
}
.layout-fix:after{
	content: ". .";
	display: block;
	font-size:0;
	height: 0;
	word-spacing: 99in;
}
input,
textarea,
select{
	font: 100% 'Lato Regular','PT Sans', Arial, Helvetica, sans-serif;
	color: #333;
	outline: none;
}

input::-moz-placeholder {
	color: #b3b3b3;
	opacity: 1;
}
input:-ms-input-placeholder {
	color: #b3b3b3;
}
input::-webkit-input-placeholder {
	color: #b3b3b3;
}
button::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner{
	padding:0;
	border:0;
}
input[type="checkbox"],
input[type="radio"]{
	margin: 0;
	padding: 0;
	width: 16px;
	height: 15px;
}
.checkbox,
.radio {
	position: relative;
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	line-height: 20px;
}
.checkbox label,
.radio label {
	cursor: pointer;
	margin-bottom: 0;
	min-height: 20px;
	padding-left: 20px;
	display: inline-block;
}
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
	margin: 3px 0 0 -20px;
	position: absolute;
}
.alert,
.info {
	background: #ffeded;
	border: 1px solid #f00;
	color: #f00;
	font-size: 16px;
	line-height: 19px;
	padding: 6px 14px;
	margin: 0 0 12px;
}
.info {
	background: #d6e9c6;
	border: 1px solid #3c763d;
	color: #3c763d
}
.hint{
	padding: 5px;
	font-weight: bold;
}


.select-search-text,
.selectize-input,
.btn-info-control,
.form-control{
	border: 1px solid #d7d7d7;
	background: #fff;
	line-height: 22px;
	width: 100%;
	height: 30px;
	padding: 3px 6px;
	margin: 0;
	box-shadow: none;
	border-radius: 0;
	vertical-align: top;
}
select.form-control{
	padding-right: 4px;
}
.search-wrap .search-form .select-search-text{
	border-left: 0;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	padding-right: 30px;
}
.select-search-text:after{
	content: "\f107";
	font-family: 'FontAwesome';
	position: absolute;
	right: 8px;
	top: 7px;
	color: #333;
	font-size: 18px;
}
.select-search select{
	position: absolute;
	top: 100%;
	left: -1px;
	margin: -1px 0 0;
	height: 160px;
	padding: 0;
	/*display: none;*/
	z-index: 3;
}
.select-search select option{
	padding: 0 10px;
}
.select-search.open select{
	display: block;
}
.select-search.open .select-search-text:after{
	content: "\f106";
}



textarea.form-control,
textarea{
	height: auto;
	overflow: auto;
}
.form-group.offset-top{
	padding-top: 15px;
}
.form-group[class*="span"]{
	padding: 0 15px;
	float: left;
}
.form-group.no-label{
	padding-top: 23px;
}
.form-group.span1{
	width: 100px;
}
.form-group.span1-1{
	width: 130px;
}
.form-group.span1-2{
	width: 170px;
}
.form-group.span2{
	width: 200px;
}
.form-group.span2-1{
	width: 230px;
}
.form-group.span2-2{
	width: 270px;
}
.form-group.span3{
	width: 300px;
}
.form-group.span3-1{
	width: 330px;
}
.form-group.span3-2{
	width: 370px;
}
.form-group.span4{
	width: 400px;
}
.form-group.span4-1{
	width: 430px;
}
.form-group.span4-2{
	width: 470px;
}
.form-group.span5{
	width: 500px;
}
.form-group.span6{
	width: 600px;
}
.form-group.span7{
	width: 700px;
}
.form-group.span8{
	width: 800px;
}
.form-group.span9{
	width: 900px;
}
.form-group.span10{
	width: 1000px;
}

.form-group.no-label .checkbox{
	display: inline-block;
	vertical-align: top;
	margin: 14px 10px 11px 0;
}
.input-group{
	display: table;
	width: 100%;
}
.input-group>*{
	display: table-cell;
	vertical-align: top;
}
.input-group .form-control:first-child{
	border-right: 0;
}
.input-group-btn{
	width: 1%;
}

.label{
	display: block;
	font-weight: bold;
	padding: 0 0 5px;
	color: #666;
}
.label-left{
	float: left;
	font-weight: bold;
	padding: 11px 10px 0;
	width: 90px;
	color: #666;
}
.label.required:before{
	content: '*';
	color: #db4444;
	font-size: 18px;
}
.row .controls{
	margin: 0 0 0 120px;
}
.change-list li{
	padding: 14px 0 0;
}
.change-list li:first-child{
	padding: 0;
}
.info-text{
	display: block;
	padding: 4px 0 0;
	clear: both;
}
.errorMessage{
	color: #db4444;
	font-size: 12px;
	line-height: 15px;
	margin: -6px 0 3px;
	display: none;
}
.error-field .errorMessage{
	display: block;
}
.login-form .errorMessage{
	position: absolute;
	bottom: 100%;
	margin: 0 0 5px;
}
.double-line{
	height: 3px;
	border: solid #d7d7d7;
	border-width: 1px 0;
	margin: 15px 0;
}
.modal-main .double-line{
	padding: 0 30px;
	margin: 0 -30px 15px;
}
.btn-info{
	vertical-align: top;
	width: 58px;
}
.btn-info-control{
	display: block;
	position: relative;
	background: #f2f2f2;
	cursor: pointer;
	z-index: 2;
	transition: none;

}
.btn-info-control:after{
	content: '';
	position: absolute;
	bottom: 0;
	left: 2px;
	right: 2px;
	height: 2px;
	background: #f2f2f2;
	border: 1px solid #d7d7d7;
	border-top: 0;
	margin: 0 0 -3px;
	z-index: 1;
}
.btn-info-control:before{
	content: "\f107";
	position: absolute;
	right: 9px;
	color: #221f20;
	font-size: 18px;
	top: 14px;
	font-family: fontawesome;
}
.btn-info.active .btn-info-control:after,
.btn-info-control:hover:after{
	opacity: 0;
}
.btn-info.active .btn-info-control:before{
	content: "\f106";
	top: 13px;
}
.btn-info-control .fa-info-circle{
	color: #b3b3b3;
	font-size: 24px;
}
.info-drop{
	position: absolute;
	right: -415px;
	top: 55px;
	width: 830px;
	border: 1px solid #d7d7d7;
	background: #fff;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
	z-index: 10;
	max-height: 370px;
	overflow-y: auto;
	margin: -1px 0 0;
	transition: all 0.2s ease;
}
.btn-info.active .info-drop{
	opacity: 1;
	visibility: visible;
}
.btn-info.active .btn-info-control{
	z-index: 11;
	background: #fff;
	border-bottom: 0;
}
.info-drop .table{
	margin: 0;
	color: #333;
}
.info-drop .table th{
	font-size: 14px;
	line-height: 18px;
	font-weight: bold;
	border-color: transparent !important;
}
.info-drop .table th,
.info-drop .table td{
	padding: 11px 10px;
}
.info-drop .table th:first-child,
.info-drop .table td:first-child{
	padding-left: 20px;
}

/* --- DUPLICATE, just for login --- */
h1,
.main-title{
	font-size: 30px;
	line-height: 40px;
	color: #333;
	margin: 0 0 20px;
}
/*h2{
	font-size: 21px;
	line-height: 20px;
	font-weight: bold;
	margin: 0 0 12px;
}*/
/* --- END DUPLICATE, just for login --- */

//.file-name{
//	display: inline-block;
//	vertical-align: middle;
//	color: #b3b3b3;
//	font-size: 16px;
//	margin: 0 0 0 6px;
//	max-width: 100%;
//}
//.in-file{
//	overflow: hidden;
//}
//.in-file .btn-file-wrap{
//	float: left;
//}
//.in-file .file-name{
//	overflow: hidden;
//	display: block;
//	padding: 15px 0 0 10px;
//	margin: 0;
//	text-overflow: ellipsis;
//	white-space: nowrap;
//}
//.total-progress-box{
//	clear: both;
//	padding: 1px 0 0;
//}
//.total-progress{
//	height: 1px;
//	background: #f27c23;
//}
//.wpcf7-form .wpcf7-submit {
//	background-color: #000;
//	font-size: 20px;
//	color: #ffd855;
//	border-radius: 10px;
//	padding: 10px 45px;
//	border: 5px double #ffd855;
//	border-collapse: separate;
//	width: 50%;
//}

// ----- DUPLICATE -----
/*
.cover-image{
	border: 1px dashed #c2c2c2;
	margin: 5px 0 0;
	text-align: center;
	display: table;
	table-layout: fixed;
	height: 238px;
	width: 100%;
	float: left;
}
.cover-image-holder{
	display: table-cell;
	vertical-align: middle;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1) inset;
	height: 236px;
	width: 100%;
	position: relative;
}
.cover-image .btn-file{
	text-transform: uppercase;
	font-size: 18px;
	line-height: 21px;
	vertical-align: top;
}
.cover-image .btn-file strong{
	display: block;
	font-size: 20px;
}
.cover-image img{
	max-height: 100%;
	width: auto;
}

 */
// ----- END DUPLICATE -----

//.badge{
//	background: #149100;
//	color: #fff;
//	display: inline-block;
//	font-size: 14px;
//	line-height: 1;
//	padding: 2px 5px;
//	text-align: center;
//	vertical-align: baseline;
//	white-space: nowrap;
//	-webkit-border-radius: 2px;
//	border-radius: 2px;
//}
//.badge-muted{
//	background: #666;
//}

/* --- DUPLICATE, just for login --- */
//.logo {
//	float: left;
//	width: 129px;
//	height: 74px;
//	text-indent: -9999px;
//	overflow: hidden;
//	background: url('/images/logo.svg') no-repeat 0 0;
//	background-size: contain;
//}
//.logo a {
//	display: block;
//	height: 100%;
//}

//.customer-logo {
//	float: left;
//	width: 200px;
//	height: 74px;
//	line-height: 72px;
//}
//.customer-logo img {
//	vertical-align: middle;
//	max-height: 100%;
//	width: auto;
//}


/* --- END DUPLICATE, just for login --- */


/*
.dot.red{
	background: #fc0000;
}
.dot.green{
	background: #3fb549;
}
*/

.cart{
	width: 1.0009765625em;
}

#wrapper{
	min-height: 100%;
	overflow-x: hidden;
	padding: 0 10px;
}
.search-form-wrap,
.w1{
	margin: 0 auto;
	max-width: 1570px;
	padding: 0 0 10px;
}
.search-form-wrap{
	padding: 0;
}
#header{
	padding: 24px 0 19px;
}


.date-group{
	margin: 0 -15px;
}
.date-group>div{
	float: left;
	max-width: 230px;
	width: 50%;
	padding: 0 15px;
	position: relative;
}
.date-group>div .error-message{
	margin-right: 15px !important;
}





.ui-datepicker{
	z-index: 300 !important;
}

label {
	/*font-size: 16px;*/
	line-height: 20px;
	display: block;
	padding: 0 0 3px;
}
/*.label-inline{
	font-size: 16px;
	line-height: 27px;
	display: inline;
	padding: 0 10px 3px;
}*/

.has-error{
	position: relative;
}
.has-error .form-control, .has-error .selectize-input{
	border-color: #f00;
	color: #f00;
}
.error-message{
	position: absolute;
	background: #fff;
	left: 0;
	top: 100%;
	margin: 2px 0 0 !important;
	font-size: 14px;
	line-height: 18px;
	padding: 0 7px 2px;
	color: #f00;
	z-index: 99;
}
.form-group:not(.has-error)>.error-message{
	display: none;
}

/*
.half-block{
	width: 50%;
	float: left;
	padding: 0 15px;
}
*/
.row [class*="span"].has-error .error-message{
	margin: 0 -15px;
}
/*
.half-item{
	float: left;
	width: 50%;
	padding: 0 15px;
}
*/

/*
.systematic .sub-table{
	table-layout: fixed;
}
*/

/*
.gray-wrap{
	margin: 0 -12px;
}
.gray-block{
	float: left;
	margin: 0 12px 48px;
	padding: 12px 11px 12px 12px;
	width: 423px;
	background: #f2f2f2;
}
*/





ul.info-list{
	padding: 0 0 9px;
}
.info-list li{
	padding: 3px 0 0;
}
.info-list li:first-child{
	padding: 0;
}

.info-list li span.additional-line {
	padding-left: 20px;
	display: inline-block;

}

.info-list-indented li p {
	text-indent: -20px;
}

.info-list-indented li {
	padding-left: 20px !important;
}

.info-list-indented li p {
	margin: 0;
	padding: 0;
}

//.opener-title{
//	color: #f27c23;
//	font-size: 21px;
//	line-height: 26px;
//	border-bottom: 1px solid #ccc;
//	padding: 0 25px 5px 0;
//	margin: 0 0 6px;
//	cursor: pointer;
//	position: relative;
//}
//.opener-title:after{
//	content: "\f106";
//	font-family: 'FontAwesome';
//	font-size: 24px;
//	font-weight: normal;
//	position: absolute;
//	right: 5px;
//	color: #000;
//}
//.opener-copies{
//	margin-left: 19px;
//	cursor: pointer;
//}
//.opener-copies:before{
//	content: "\f106";
//	font-family: 'FontAwesome';
//	font-size: 18px;
//	font-weight: normal;
//	position: absolute;
//	left: 17px;
//	color: #000;
//}
//.open-close.close .opener-title:after,
//.open-close-copies.close .opener-copies:before{
//	content: "\f107";
//}
//.open-close{
//	margin: 0 0 27px;
//}
//.open-close.close .open-close-content,
//.open-close-copies.close .open-close-content{
//	display: none;
//}

//.file-link{
//	text-decoration: underline;
//	color: #01a2ff;
//}
//.file-link:hover{
//	text-decoration: none;
//}

/*
span.opener-arrow{
	color: #888;
}
span.opener-arrow:after {
	content: "ï„‡";
	font-family: 'FontAwesome';
	margin: 0 0 0 6px;
	cursor: pointer;
	font-size: 24px;
}
*/

@media(max-width: 1590px){
	.search-form-wrap,
	.w1{
		max-width: 1170px;
	}
	.half-item{
		width: 63%;
	}
	.half-item:first-child{
		width: 37%;
	}
	.users-table .col-name{
		width: 180px;
	}
}
@media(max-width: 1199px){
	.users-table .col-name{
		width: 140px;
	}
	.search-form-wrap,
	.w1{
		max-width: 1000px;
	}
	.expert-holder{
		padding: 15px 15px;
	}
	.expert-table + .expert-table{
		padding: 0 0 0 40px;
	}
	.date-group{
		margin: 0 -7px;
	}
	.date-group > div{
		padding: 0 7px;
	}
	.date-group .fa{
		right: 18px;
	}
	.table-scroll.scroll-active{
		margin: 0;
	}
	.table th,
	.table td{
		padding: 10px 7px;
	}
	.sort-link{
		padding: 0 10px 0 0;
	}
	.users-table .col-actions{
		width: 93px;
	}
	.book-cover{
		width: 60px;
	}
	.logo.alt ~ #nav{
		float: none;
		text-align: center;
		clear: both;
		padding: 12px 0 5px;
	}
	.logo.alt ~ #nav>li{
		display: inline-block;
		vertical-align: top;
		margin: 0 35px;
		float: none;
	}
	.logo.alt ~ #nav>li.hover,
	.logo.alt ~ #nav > li:hover{
		margin-bottom: -10px;
	}
	.nav-drop{
		text-align: left;
	}
	.main-half{
		margin: 0;
	}
	.half-item{
		padding: 0;
		float: none;
		width: 100% !important;
	}
	.sub-table .edit-tr .in-holder{
		margin: -5px 0;
	}
	.sub-table th, .sub-table td{
		padding: 10px 5px 10px 0;
	}
	.sub-table .col-actions{
		width: 78px;
	}
}



//.button-bottom{
//	display:flex;
//}
//.button-bottom .col-md-2, .button-bottom .col-md-10{
//	flex-grow: 1;
//}
//.button-bottom .col-md-2 .button{
//	position:relative;
//}
//.button-bottom .col-md-2 .btn{
//	position:absolute;
//	bottom:-120px;
//}

//tip {
//	white-space: nowrap;
//}
