/*
 * FORM ITEM LEFT: forms where label is left and input/select, .. is on the right
 */
//.btn.create-new-item{
//  margin-bottom:5px !important;
//}

.form-group[class*="span"]{
  padding-left: 0px;
  float: left;
}

form{
  /*h2{
    font-size: @font-size-h2-small;
    margin:30px 0 5px 0;
  }
  */

  .row{
    /*margin:5px;*/
    /*margin: 0 0 7px 0;*/
   &.button-row{
     margin-top:20px;
   }
  }
  .form-item-left{
    &.checkbox{
      input[type="checkbox"]{
        position:relative;
        margin:3px 180px 0 0 ;
      }
    }
    input[type="checkbox"], input[type="radio"]{
      margin-top:7px;
    }

    input, select, textarea, span.value{
      float:right;
    }

    input[type="text"], select, textarea, span.value{
      width:200px;
    }

    &.selectbox, &.inputbox{
      margin-bottom:10px;
    }

    label{
      padding-left:0px;
      font-size: @font-size-base;
      display:block;
    }

    &.checkbox label:after, &.selectbox:after, &.inputbox label:after{
      content:'';
      display:table;
      clear:both;
    }

    &.checkbox label>span, &.selectbox label, &.inputbox label{
      width:400px;
      display:inline-block;
    }

    &.inputbox label.small-space{
      width:200px;
    }

  }
}

.btn.create-new-item{
  display:block;
  float:right;
  a{
    color:white;
    text-decoration: none;
  }
}
