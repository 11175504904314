@font-face {
	font-family: 'julit-icons';
	src:
		url('../fonts/julit-icons.ttf') format('truetype'),
		url('../fonts/julit-icons.woff') format('woff'),
		url('../fonts/julit-icons.svg#julit-icons') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'julit-icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	font-size: 24px;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-edit:before {
  content: "\e900";
}
.icon-trash:before {
  content: "\e901";
}
.icon-restore:before {
  content: "\e902";
}
.icon-gear:before {
  content: "\e91f";
}
.icon-attachment:before {
  content: "\e903";
}
.icon-link:before {
  content: "\e904";
}
.icon-star:before {
  content: "\e905";
}
.icon-copy:before {
  content: "\e906";
}
.icon-cart:before {
  content: "\e907";
}
.icon-printer:before {
  content: "\e908";
}
.icon-empty-circle:before {
  content: "\e909";
}
.icon-filled-circle:before {
  content: "\e90a";
}
.icon-add:before {
  content: "\e90b";
}
.icon-envelope:before {
  content: "\e90c";
}
.icon-cross-circle:before {
  content: "\e90d";
}
.icon-pdf:before {
  content: "\e90e";
}
.icon-dnb:before {
  content: "\e90f";
}
.icon-stack:before {
  content: "\e910";
}
.icon-save:before {
  content: "\e911";
}
.icon-download:before {
  content: "\e912";
}
.icon-chevron-left:before {
  content: "\e913";
}
.icon-chevron-right:before {
  content: "\e914";
}
.icon-menu-up:before {
  content: "\e915";
}
.icon-menu-down:before {
  content: "\e916";
}
.icon-sort-desc:before {
  content: "\e917";
}
.icon-sort-asc:before {
  content: "\e918";
}
.icon-checkbox:before {
  content: "\e919";
}
.icon-checked:before {
  content: "\e91b";
}
.icon-return:before {
  content: "\e91c";
}
.icon-lens:before {
  content: "\e91d";
}
.icon-convert-copy:before {
  content: "\e91e";
}
.icon-add-number:before {
  content: "\e920";
}
.icon-close:before {
  content: "\e91a";
}
.icon-goto:before {
  content: "\e921";
}
.icon-stock:before {
  content: "\e922";
}
.icon-invisible:before {
  content: "\e924";
}
.icon-visible:before {
  content: "\e923";
}
.icon-move:before {
  content: "\e925";
}

/* --- CHECK STYLES BELOW FOR REMOVAL --- */

/* --- artifacts --- */

/*.in-number {
	position: relative;
}

.in-number .icon {
	position: absolute;
	right: auto;
	left: 8px;
	top: 3px;
	cursor: pointer;
	color: #b3b3b3;
}

.in-number .icon:hover {
	color: #959595;
}

.in-number .form-control {
	padding: 3px 6px 3px 32px;
}*/

/* --- more artifacts from general/icons.less --- */

.icon-group {
	overflow: hidden;
	vertical-align: top;
	display: flex;
}
.users-table .icon-group {
	white-space: nowrap;
}
.icon-group:last-child > span {
	margin-bottom: 5px;
	margin-top: 1px;
}
.icon-group > span {
	border-left: 1px solid #dadada;
	box-shadow: 1px 0 0 #fff inset;
	height: 24px;
	line-height: 24px;
	display: inline-block;
	margin: 0 0 10px -2px;
	padding: 0 0 0 2px;
	width: 35px;
	text-align: center;
}

/*-- icon-group .icon vs class^="-icon", see above -- */

.icon-group .icon {
	font-size: 22px;
	text-decoration: none;
	transition: all 0.2s ease;
	width: 30px;
	text-align: center;
}

a.icon {
	color: #8c8c8c;
}

a.icon:hover {
	color: @color-brand;
}

.col-actions .icon-group button,
.col-actions .icon-group a {
	transition: all 0.2s ease;
	cursor: pointer;
	display: block;
}
.col-actions .icon-group button:hover,
.col-actions .icon-group button.active,
.col-actions .icon-group a.active,
.col-actions .icon-group a:hover {
	opacity: 1;
}
.col-actions .icon-group button {
	border: none;
	padding: 0;
	margin: 0;
	background-color: transparent;
}
.removed-tr td>*:not(.icon-group) {
	opacity: 0.5;
}
.col-actions .icon-group .ta-restore {
	opacity: 1;
}
.icon-group.restore{
	display: none;
}
.removed-tr .icon-group:not(.restore) {
	display: none;
}
.removed-tr .icon-group.restore {
	display: block;
}

/* ----- */
.icon-group .icon:hover,
.icon-group .icon.active,
.icon-group a.active .svg,
.icon-group a .svg:hover {
	color: #f27c23;
}

/* ---- */
.icon-group {
	.fa{
		font-size:22px;
	}
	.fa-pencil, .fa-list, .fa-plus{
		color:#010101;
	}
}

/*
.svg {
	width: 1em;
	height: 1.01em;
	display: inline-block;
	vertical-align: middle;
	fill: currentcolor;
	font-size: 28px;
	color: #8c8c8c;
	transition: all 0.3s ease;
}

.fa {
	&.fa-plus-square-o{
		font-size: 31px;
		line-height:18px;
	}
}

[class^="icon-"], [class*=" icon-"] {
	line-height: 23px;
}
*/
