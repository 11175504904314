@media print {
  // general
  //body > * {display: none}
  //#print_block, #print_block > * {display: block; }

   #print_block{
     //padding:15px;
     font-size: 12pt;
     h2{
       font-size:18pt;
     }
     h3{
       font-size:16pt;
     }
     // detail view
     .open-close{
       .opener-title:after, .opener-copies:before{
         content: '';
       }
     }
     .text-muted{
       color:#333;
     }
   }

  #detail-column-left .icon-group {
    display: none;
  }

  #detail-column-left {
    float: left;
    width: 241px;
  }

  .book-content {
    margin: 0 0 0 270px;
  }

  .close-modal {
    display: none;
  }
}
