/**
 * IMPORT FILES
 */

@font-face {
	font-family: 'Arial';
	src: url('../fonts/Arial.ttf') format('truetype');
}

@font-face {
	font-family: 'arialbd';
	src: url('../fonts/arialbd.ttf') format('truetype');
}

@font-face {
	font-family: 'DejaVu Sans Condensed';
	src: url('../fonts/DejaVuSansCondensed.ttf') format('truetype');
}

@font-face {
	font-family: 'DejaVu Sans Condensed bold';
	src: url('../fonts/DejaVuSansCondensed-Bold.ttf') format('truetype');
}

.pull-right {
	float: right;
}

.pull-left {
	float: left;
}

.no-border, .table-scroll.no-border {
  border: none;
}



@import './lato.less';
@import './julit-icons.less';

// variables
@import 'general/variables.less';

// general site structure
@import 'general/table.less';
@import 'general/print.less';
/* --- @import 'general/icons.less'; --- */
@import 'general/tooltip.less';
@import 'general/button.less';

// forms
@import 'forms/general.less';

@import 'existing-css/___login.less';
@import 'existing-css/___table.less';
@import 'existing-css/_all.less';

@import 'lists';







/*.hide-content{
  display:none;
}*/
.login-page{
  width:1000px;
}

ul#nav {
	user-select: none;
}
#nav{
	float: right;
	padding: 28px 0 0;
	font-size: 16px;
	line-height: 24px;
}
#nav>li{
	float: left;
	margin: 0 0 0 45px;
	position: relative;
	transition: all 0.2s ease;
}
#nav>li.hover,
#nav>li:hover{
	padding: 0 0 10px;
	margin-bottom: -10px;
}
#nav>li>a{
	color: #333;
	text-transform: uppercase;
	transition: all 0.2s ease;
	display: block;
	padding: 0 20px 0 0;
}
#nav>li>a:after{
	content: "\e916";
	font-family: 'julit-icons';
	transition: all 0.2s ease;
	position: absolute;
	right: 0;
	top: -1px;
	font-size: 10px;
}
#nav>li>a.single{
	padding-right: 0;
}

#nav>li>a.single:after {
	content: "";
}

#nav>li.hover>a:after,
#nav>li:hover>a:after{
	/*content: "\f106";*/
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}
#nav>li.hover>a,
#nav>li:hover>a{
	text-decoration: none;
	color: #f27c23;
}
#nav a{
	color: #333;
}
.nav-drop{
	position: absolute;
	top: 100%;
	left: 0;
	background: #fff;
	border: 1px solid #ddd;
	opacity: 0;
	visibility: hidden;
	z-index: 5;
	white-space: nowrap;
	transition: all 0.3s ease;
	padding: 6px 0;
}
#nav>li.hover .nav-drop,
#nav>li:hover .nav-drop{
	opacity: 1;
	visibility: visible;
}
#nav>li:last-child .nav-drop{
	left: auto;
	right: 0;
}
.nav-drop a{
	display: block;
	padding: 6px 15px;
}
#nav .nav-drop a:hover{
	text-decoration: none;
	color: #f27c23;
}


/*.tabs>div{

}*/


/*
.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { zoom: 1; }
*/
