
@font-face {
	font-family: 'Lato Regular';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/lato-v16-latin-regular.eot'); /* IE9 Compat Modes */
	src:
		local('Lato Regular'),
		/* url('../fonts/lato-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /IE6-IE8 */
		url('../fonts/lato-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/lato-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
		url('../fonts/lato-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/lato-v16-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Lato Light Regular';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/lato-v16-latin-300.eot'); /* IE9 Compat Modes */
	src:
		local('Lato Light Regular'),
		/*url('../fonts/lato-v16-latin-300.eot?#iefix') format('embedded-opentype'), IE6-IE8 */
		url('../fonts/lato-v16-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/lato-v16-latin-300.woff') format('woff'), /* Modern Browsers */
		url('../fonts/lato-v16-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/lato-v16-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Lato Bold';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/lato-v16-latin-700.eot'); /* IE9 Compat Modes */
	src:
		local('Lato Bold'),
		/* url('../fonts/lato-v16-latin-700.eot?#iefix') format('embedded-opentype'), IE6-IE8 */
		url('../fonts/lato-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/lato-v16-latin-700.woff') format('woff'), /* Modern Browsers */
		url('../fonts/lato-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/lato-v16-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Lato Italic';
	font-style: italic;
	font-weight: normal;
	src: url('../fonts/lato-v16-latin-italic.eot'); /* IE9 Compat Modes */
	src:
		local('Lato Italic'),
		/* url('../fonts/lato-v16-latin-italic.eot?#iefix') format('embedded-opentype'), IE6-IE8 */
		url('../fonts/lato-v16-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/lato-v16-latin-italic.woff') format('woff'), /* Modern Browsers */
		url('../fonts/lato-v16-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/lato-v16-latin-italic.svg#Lato') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Lato Light Italic';
	font-style: italic;
	font-weight: 300;
	src: url('../fonts/lato-v16-latin-300italic.eot'); /* IE9 Compat Modes */
	src:
		local('Lato Light Italic'),
		/* url('../fonts/lato-v16-latin-300italic.eot?#iefix') format('embedded-opentype'), IE6-IE8 */
		url('../fonts/lato-v16-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/lato-v16-latin-300italic.woff') format('woff'), /* Modern Browsers */
		url('../fonts/lato-v16-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/lato-v16-latin-300italic.svg#Lato') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Lato Bold Italic';
	font-style: italic;
	font-weight: 700;
	src: url('../fonts/lato-v16-latin-700italic.eot'); /* IE9 Compat Modes */
	src:
		local('Lato Bold Italic'),
		/* url('../fonts/lato-v16-latin-700italic.eot?#iefix') format('embedded-opentype'), IE6-IE8 */
		url('../fonts/lato-v16-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/lato-v16-latin-700italic.woff') format('woff'), /* Modern Browsers */
		url('../fonts/lato-v16-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/lato-v16-latin-700italic.svg#Lato') format('svg'); /* Legacy iOS */
}

